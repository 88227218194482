.navbar-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;

    @include respond-to("xs") {
        padding: 1rem 0.75rem;
    }

    .logo-container{
        .logo-text{
            font-family: $font-secondary;
            font-size: 1.75rem;

            @include respond-to("xs") {
                font-size: 1rem;
            }
        }
    }

    .social-links-container{
        .links{
            display: flex;
            gap: 1rem;

            @include respond-to("xs") {
                gap: 0.5rem;
            }
        }
    }
}