.notfound-container {
  height: 64vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .notfound-heading-container {
    .notfound-heading {
      font-size: 25rem;
      background: linear-gradient(to right, #f4f4f5, #262626);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      line-height: 1;
      font-weight: 500;

      @include respond-to("md") {
        font-size: 18rem;
      }

      @include respond-to("sm") {
        font-size: 10rem;
      }
    }
  }

  .notfound-message-container {
    border-radius: 5rem;
    background-color: rgba(4, 0, 20, 0.4);
    backdrop-filter: blur(10px);
    padding: 1rem 1.5rem;
    margin-top: -8.5rem;
    text-align: center;
    max-width: 500px;
    width: 100%;

    @include respond-to("md") {
      margin-top: -6rem;
    }

    @include respond-to("sm") {
      margin-top: -3.5rem;
      padding: 1rem;
    }

    .notfound-message {
      font-size: 1.85rem;

      @include respond-to("sm") {
        font-size: 1rem;
      }
    }

    .notfound-submessage {
      font-size: 1rem;
      color: $text-gradient;
      margin-bottom: 2.5rem;

      @include respond-to("sm") {
        font-size: 0.65rem;
        margin-bottom: 1.5rem;
      }
    }

    .notfound-button {
      padding: 0.5rem 1.5rem;
      border-radius: 2rem;
      border: none;
      background: linear-gradient(to right, $primary-background-color, #49269933);
      color: $primary-text-color;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      @include respond-to("sm") {
        font-size: 0.9rem;
        padding: 0.4rem 1rem;
      }
    }
  }
}
