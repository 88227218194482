a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

button {
  font-family: $font-primary;
}
