* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-size: 16px;
  font-family: $font-primary;
  color: $primary-text-color;
  background-color: $primary-background-color;
  user-select: none;
  overflow-x: hidden;
}
