.footer-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    border-top: 1px solid #242424;
    @include respond-to("xs") {
        padding: 2rem 0.75rem;
    }
    .footer-text-container{
        .footer-text{
            font-size: 1rem;
            @include respond-to("xs") {
                font-size: 0.75rem;
            }
        }
    }
    
    .social-links-container{
        .links{
            display: flex;
            gap: 1rem;
            @include respond-to("xs") {
                gap: 0.5rem;
            }
        }
    }
}


